.l-main-form {
  width: 80%;
  margin-inline: auto;
  background-color: var(--background-color);
  border-radius: 1rem;
  padding: 1.5rem;

  @include respond(tab-port) {
    padding: 3rem;
  }
}
