@font-face {
  font-family: 'Gotham';
  src: url('./../../assets/fonts/Gotham-Book.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./../../assets/fonts/Gotham-Medium.otf');
  font-weight: 700;
  font-style: bold;
}

