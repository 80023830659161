.p-component {
  font-size: 1.2rem;
}

/// p-buttons ///
.p-button {
  background-color: var(--primary-color-3);
  padding: .9rem;
  font-size: 1.6rem;
  color: var(--primary-color-text);

  &:focus {
    outline: none;
    box-shadow: none;
  }

  // Button warning
  &-warning,
  &-group.p-button-warning > &,
  .p-splitbutton.p-button-warning > & {
    background-color: var(--primary-color);
  }
}


/// InputText ///
.p-inputtext {
  padding: 1.2rem;
  font-size: 1.2rem;

  :focus {
    box-shadow: none;
  }

  &:enabled:focus {
    box-shadow: none;
    border-color: var(--primary-color-2);
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--primary-color-2);
}


/// MODALES ///
.p-dialog {
  &-header {
    flex-direction: column-reverse;
  }

  .title-modal {
    font-size: 1.7rem;
    color: var(--secondary-color-2);
    font-weight: 700;
    text-align: center;
  }

  .p-dialog-header-icons {
    align-self: end;
    justify-content: center;
    margin: 2rem 2rem 0;
    position: relative;
    background-color: var(--secondary-color);
    border-radius: 50%;

    &::before {
      content: 'Cerrar';
      display: block;
      position: absolute;
      color: var(--primary-color-2);
      bottom: -1.5rem;
    }

    &:empty::before {
      display: none;
    }
  }

  &-header &-header-icon {
    color: var(--primary-color-2);
    background-color: transparent;
  }
}


/// Accordion ///
.p-accordion {
  font-size: 1.6rem;
  p-accordiontab .p-accordion-tab {
    margin-bottom: 1.6rem;
  }

  .p-accordion-header .p-accordion-header-link {
    padding: 1.25rem;
  }

  &.primary-color-icons .pi {
    color: var(--primary-color);
    opacity: .5;
    order: 10;
  }
}


/// DROPDOWN ///
.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      padding: 1.25rem;
    }
  }
}

.phone-details__dropdown {
  width: 100%;

  .p-inputtext {
    padding: 1rem;
    // font-size: 1rem;
    box-sizing: border-box;
    width: 10rem;
  }

  .p-icon-wrapper {
    color: var(--primary-color);
  }
}

/// p-calendar ///


// .p-datepicker-touch-ui, .p-calendar .p-datepicker-touch-ui {
//   max-width: 500px;
// }
// .p-datepicker {
//   padding: .6rem;
//   font-size: 1.2rem;

//   .p-datepicker-header {
//     padding: .6rem;
//   }

//   table {
//     margin: .6rem;
//     font-size: 1.2rem;
//     td {
//       padding: 1.2rem;
//     }
//   }

//   .p-link {
//     font-size: inherit;
//     margin-right: 1.2rem;
//   }
// }


/// Personalindo el p-tabview de primeNg///
.p-tabview {
  font-family: $font-family;

  li {
    width: 50%;
    text-align: center;

    .p-tabview-nav-link {
      // background-color: red;
      display: block;
      color: $primary-color;
      border-color: white;
      border-bottom-width: 0.3rem;
      font-weight: 400;

      /*
        Le puse el important para deshabilitar el box-shadow por culpa de la especificidad que contiene el selector por defecto de primeNg
      */
      &:not(.p-disabled):focus {
        box-shadow: none !important;
      }


    }
  }

  .ng-star-inserted.p-highlight {
    .p-tabview-nav-link {
      border-color: $primary-color-2;
    }
  }

}

///
/// Personalizamos el color de los botones de navegación del p-galleria de primeNg
///
.p-galleria {
  & .p-galleria-item-nav {
    color: var(--primary-color);

    // &:not(.p-disabled):hover {
    //   color: inherit;
    // }
  }

  & .p-galleria-item-nav .p-icon-wrapper .p-icon {
    width: 4rem;
    height: 4rem;
    stroke-width: 1;
  }
}


///
/// Modificamos el color cuando la clase es igual al .ng-invalid en el formulario del cliente
///
.formulario-cliente {

  .ng-invalid,
  .ng-invalid .p-dropdown {
    border-color: #e24c4c;
  }
}

///
/// Modificamos la orientación del los botones de navegación del p-galleria unicamente en la página de del detalle-producto.component.html
///
.detail-phones__img-container {

  button.p-ripple.p-element.p-galleria-item-prev.p-galleria-item-nav.p-link.ng-star-inserted,
  button.p-ripple.p-element.p-galleria-item-next.p-galleria-item-nav.p-link.ng-star-inserted {
    margin-left: -3rem !important;
    margin-top: -2rem;
    font-family: $font-family;
    font-size: 2rem;
  }

  button.p-ripple.p-element.p-galleria-item-next.p-galleria-item-nav.p-link.ng-star-inserted {
    margin-right: -3rem;
  }
}

.p-link:focus {
  box-shadow: none;
}

.p-button.p-button-icon-only.p-button-rounded.backButton {
  width: initial;
  height: initial;
}


////
/// Cambio del tamaño de la fuente en el dropdown si se encuenta en el contenedor .filter-container__dropdown-container
///
.filter-container__dropdown-container {
  .p-inputtext {
    font-size: 0.8rem !important;
  }

  .p-dropdown-clear-icon.p-icon {
    top: .5rem;
  }

  ///
  /// Código para cambiar el padding de los placeholder de los dropdown del filtro de en la pagina de sucursale-page.html
  ///
  @at-root .sucursales-container__dropdown-container {
    .p-dropdown .p-dropdown-label.p-placeholder {
      padding-right: 0;
    }
  }
}

////
/// Cambio de dirección del ícono de limpieza del dropdown para el filtro de sucursales
///
.filter-container___dropdown-container {
  .p-dropdown-clear-icon.p-icon {
    top: 9px;
  }
}

///
/// Cambiar la familia de fuente a los .p-inputtext
///
.p-inputtext {
  font-family: $font-family;
}



///
/// Cambiamos el padding de las clases .p-dialog y del .p-dialog-footer que nos ofrece el tema lara-light-blue
/// UNICAMENTE si contiene la clase welcome-term como contenedor
///

.welcome-terms {
  .p-dialog-footer {
    padding: 1.5rem;
  }
}
