:root {
  --primary-color: #FF671D;
  --primary-color-2: #6D80A4;
  --primary-color-3: #002F87;

  --secondary-color: #EDF0F9;
  --secondary-color-2: #04275A;
  --secondary-color-3: #00ABEE;

  --danger-color: #F45853;
  --warm-color: #EAC360;

  --secondary-color-text: #000;
  --primary-color-text: #FFF;

  --background-color: #FFF;
  --secondary-background: #000;
}

*,
*::after,
*::before {
    // margin: 0;
    // padding: 0;
    box-sizing: inherit;
    font-family: $font-family;
    // background-color: $background-color;
}

html {
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%;
}

// html {
//     // This defines what 1rem is
//     font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

//     @include respond(tab-land) { // width < 1200?
//         font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
//     }

//     @include respond(tab-port) { // width < 900?
//         font-size: 50%; //1 rem = 8px, 8/16 = 50%
//     }

//     @include respond(big-desktop) {
//         font-size: 75%; //1rem = 12, 12/16
//     }
// }

body {
    box-sizing: border-box;
    font-size: 1.6rem;
    // font-size: 4rem;
    // padding: 3rem;

    // @include respond(tab-port) {
    //     padding: 0;
    // }
}

h1,
h2,
h3,
p,
body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--secondary-color-2);
}

h3 {
  font-size: 1.6rem;
  font-weight: 400;
}

p {
  color: var(--primary-color-2);
}

img {
  display: block;
}

a {
  text-decoration: none;
}

ul, ol {
  list-style: none;
}
// ::selection {
//     background-color: $color-primary;
//     color: $color-white;
// }
