.btn {
  display: block;
  width: 75%;
  margin-right: auto;
  margin-left: auto;

  &.danger-button {
    background-color: var(--danger-color);
  }
}
