.l-registration-form {
  margin-top: 1rem;

  .float-label {
    input+label {
      color: var(--primary-color);
      top: 50%;
      font-size: 1.2rem;
    }
    input:focus+label,
    input:not(:placeholder-shown)+label {
      top: 0;
      background-color: var(--background-color);
    }
  }
}
