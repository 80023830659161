$primary-color: #FF671D;
$primary-color-2: #6D80A4;
$primary-color-3: #002F87;

$secondary-color: #EDF0F9;
$secondary-color-2: #04275A;
$secondary-color-3: #00ABEE;

$danger-color: #F45853;
$warm-color: #EAC360;

$text-color-light: #FFF;
$text-color-dark: #000;

$background-color: #FFF;


$font-family:  "Gotham", sans-serif;
$default-font-size: 3rem;
