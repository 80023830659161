.registro {
  &__form-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  &__input {
    font-family: $font-family;

    &::placeholder {
      text-align: center;
    }
  }

  &__code-button {
    font-family: inherit;
    &:focus {
      box-shadow: none;
    }
  }
}
