.l-header-form {
  margin-top: 2rem;
  margin-bottom: 3rem;

  &__title {
    font-size: 1.8rem;
    text-align: center;
    color: var(--primary-color-3);
  }
}
