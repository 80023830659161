.float-label {
  label {
    color: var(--primary-color);
    top: 50%;
    font-size: 1.2rem;
  }

  input:focus+label,
  .p-filled+label,
  p-calendar.ng-valid+label,
  p-dropdown.ng-valid+label,


  // p-inputmask+label,
  p-inputmask.ng-valid+label,
  p-inputmask.p-inputwrapper-focus+label,


  textarea:focus+label,
  .p-filled+label label:has(+.p-filled) {
    top: 0;
    background-color: var(--background-color);
  }

  p-calendar+label,
  p-dropdown+label {
    font-size: 1.2rem;
  }


}
