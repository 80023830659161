// MEDIA QUERY MANAGER
/*
0 - 374 px:       Small phones
375 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
$breakpoints: (
  'phone':  23.4375em, // 375px
  'tab-port': 37.5em, // 600px
  'tab-land': 56.25em, // 900px
  'desktop': 75em, //1200px
  'big-desktop': 112.5em //1800px
);

// $breakpoints: (
//   'xs': 480px,
//   'sm': 768px,
//   'md': 1024px,
//   'lg': 1280px,
//   'xl': 1440px
// );


@mixin respond($size-or-value) {
  @if map-has-key($breakpoints, $size-or-value) {
    // Si el breakpoint existe, usa su valor
    @media (min-width: map-get($breakpoints, $size-or-value)) {
      @content;
    }
  } @else if type-of($size-or-value) == number or type-of($size-or-value) == string {
    // Si se proporciona un valor dinámico, úsalo directamente
    @media (min-width: $size-or-value) {
      @content;
    }
  } @else {
    // Manejo de errores si no se pasa un valor válido
    @warn "El breakpoint `#{$size-or-value}` no es válido. Debe ser una clave predefinida o un valor de tamaño como `600px`.";
  }
}
